@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

/* @font-face {
  font-family: 'Pacifico';
  src: url('../fonts/Pacifico-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
} */

.pacifico-regular {
  font-family: "Pacifico", serif;
  font-weight: 400;
  font-style: normal;
}

#hero-section {
  position: relative;
  /* Required to position the overlay */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  animation: heroAnimation 20s infinite linear;
}

#hero-section::before {
  content: '';
  /* Creates an empty element */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Gray overlay with transparency */
  z-index: 1;
  /* Ensure it sits above the background but below other content */
}

#hero-section>* {
  position: relative;
  z-index: 2;
  /* Ensure text or other content sits above the overlay */
}


#hero-section h2 {
  color: white;
  z-index: 5;
}



/* #hero-section .sub-heading {
  font-family: "Pacifico", serif;
  font-weight: 400;
  font-style: normal;
} */

#hero-section button {
  color: white;
  z-index: 5;
}

@keyframes heroAnimation {

  0% {
    background-image: url("../../home-img/1.png");

  }

  50% {
    background-image: url("../../home-img/3.png");
  }

  100% {
    background-image: url("../../home-img/2.png");
  }


}

/* province section */

#province {
  background: url('../../home-img/3.png') no-repeat center center/cover;
  position: relative;
  background-attachment: fixed;
  height: 80vh;

}

#province:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all .2s linear;
}


#province h2,
#province p {
  color: azure;
  z-index: 5;
}

#province #sub-text {
  color: azure;
  z-index: 5;
}

#donation {
  background: url('../../home-img/donation.png') no-repeat center center/cover;
  position: relative;
  background-attachment: fixed;
  /* height: 70vh; */

}

#donation::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all .2s linear;
}


#donation h2,
#donation p {
  color: azure;
  z-index: 5;
}

#donation #sub-text {
  color: azure;
  z-index: 5;
}