
#game {
    position: relative;
    /* Required to position the overlay */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    animation: gameAnimation 20s infinite linear;
  }
  
  #game::before {
    content: '';
    /* Creates an empty element */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Gray overlay with transparency */
    z-index: 1;
    /* Ensure it sits above the background but below other content */
  }
  
  #game>* {
    position: relative;
    z-index: 2;
    /* Ensure text or other content sits above the overlay */
  }
  
  
  #game h2 {
    color: white;
    z-index: 5;
  }
  
  
  
  /* #hero-section .sub-heading {
    font-family: "Pacifico", serif;
    font-weight: 400;
    font-style: normal;
  } */
  
  #game button {
    color: white;
    z-index: 5;
  }
  
  @keyframes gameAnimation {
  
    0% {
      background-image: url("../assets/games-img/bgames.jpg");
  
    }
  
    50% {
      background-image: url("../assets/games-img/bgames2.jpg");
    }
  
    100% {
      background-image: url("../assets/games-img/bgames3.jpg");
    }
  
  
  }
  