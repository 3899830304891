@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

/* @font-face {
  font-family: 'Pacifico';
  src: url('../fonts/Pacifico-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
} */

.pacifico-regular {
  font-family: "Pacifico", serif;
  font-weight: 400;
  font-style: normal;
}